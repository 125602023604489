import React, { FunctionComponent } from 'react';
import TestimonialImage from "../assets/images/testimonial.png";
import Quotes from "../assets/icons/quotes.svg";
import Heading from "./Heading";
import Slider from "react-slick";
import TestimonialBg from "../assets/icons/testimonial_bg.svg";
import TestimonialOverlay from "../assets/images/testimonial_overlay.png";
import Next from "../assets/icons/ico_next.svg";
import Previous from "../assets/icons/ico_previous.svg";

type TestimonialsProps = {
    testimonials?: any[];
};

const testimonialsData = [
    {
        image: TestimonialImage,
        text: "I am internet entrepreneur and I was looking to expand my business to Europe. Legalia has helped me to understand business system in Slovakia and they setup everything for me in few days. Without me even visiting single state institution.",
        name: "Joshua Rodriguez",
        company: "Hi-Fi automoto",
    },
    {
        image: TestimonialImage,
        text: "I am internet entrepreneur and I was looking to expand my business to Europe. Legalia has helped me to understand business system in Slovakia and they setup everything for me in few days. Without me even visiting single state institution.",
        name: "Joshua Rodriguez",
        company: "Hi-Fi automoto",
    },
    {
        image: TestimonialImage,
        text: "I am internet entrepreneur and I was looking to expand my business to Europe. Legalia has helped me to understand business system in Slovakia and they setup everything for me in few days. Without me even visiting single state institution.",
        name: "Joshua Rodriguez",
        company: "Hi-Fi automoto",
    }
];

const TestimonialSlide = (testimonial) => (
    <div>
        <div className={'grid lg:grid-cols-2 gap-x-24'}>
            <div>
                <div className={'w-2/3 mx-auto relative pb-12'}>
                    <img src={testimonial.image} alt="" className={'object-contain relative z-10'}/>
                    <TestimonialBg className={'absolute w-full'} style={{bottom: "10px"}}/>
                    <img src={TestimonialOverlay} className={'absolute w-64 z-10'} style={{bottom: "1rem", right:"-9rem"}} alt=""/>
                </div>
            </div>
            <div className={'flex flex-col justify-center'}>
                <div className="lg:max-w-md">
                    <div className={'mb-8'}>
                        <Quotes/>
                    </div>
                    <p className={'text-h4 mb-6'}>{testimonial.text}</p>
                    <p className="mb-2">
                        <Heading type="h5" className={'font-semibold'}>{testimonial.name}</Heading>
                    </p>
                    <p className={'text-ink-60'}>
                        {testimonial.company}
                    </p>
                </div>
            </div>
        </div>
    </div>
);

const Testimonials: FunctionComponent<TestimonialsProps> = ({testimonials= testimonialsData, ...props}) => {
    const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className="slick-arrow slick-prev-icon text-ink-40 hover:text-brand-100">
            <Previous />
        </button>
    );
    const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className="slick-arrow slick-next-icon text-ink-40 hover:text-brand-100">
            <Next />
        </button>
    );

    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />
    };
    return (
        <Slider {...settings} className={'mb-16'}>
            {testimonials.map(TestimonialSlide)}
        </Slider>
    );
};

export default Testimonials;
