import React, {FunctionComponent, useState} from 'react';
import CountUp, {CountUpProps} from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

type CounterProps = CountUpProps;

const Counter: FunctionComponent<CounterProps> = ({ className, ...rest }) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return (
        <CountUp {...rest} start={viewPortEntered ? null : 0}>
            {({ countUpRef }) => {
                return (
                    <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={isVisible => {
                            if (isVisible) {
                                setViewPortEntered(true);
                            }
                        }}
                        delayedCall
                    >
                        <span className={className} ref={countUpRef} />
                    </VisibilitySensor>
                );
            }}
        </CountUp>
    );
};

export default Counter;
