import React, {FunctionComponent} from 'react';
// import { graphql } from 'gatsby';
// import TitlePage from '../components/TitlePage';
// import SEO from '../components/seo';
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import Section from "../components/Section";
import Button from "../components/Button/Button";
import StartYourBusinessImage from "../assets/images/start-your-business.svg";
import SetupImage from "../assets/images/setup.svg";
import FaqIcon from "../assets/images/faq.svg";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import HeroImage from "../assets/images/homepage-hero-image.svg";
import DoingBusinessImage from "../assets/images/doing-business.svg";
import OtherServicesImage from "../assets/images/other-services.svg";
import ClosingBusinessImage from "../assets/images/closing-business.svg";
import WhyUsUnderline from "../assets/images/why-us.png";

import Quotes from "../assets/icons/quotes.svg";

import Faqs from "../components/Faqs/index";
import LocalizedLink from "../components/LocalizedLink";
import {Link} from "gatsby";
import {useTranslation} from "react-i18next";
import Testimonials from "../components/Testimonials";
import Container from "../components/Container";
import Counter from "../components/Counter";

type IndexProps = {};

const IndexPage: FunctionComponent<IndexProps> = () => {
    const {t} = useTranslation();

    const whatImages = [
        SetupImage,
        DoingBusinessImage,
        ClosingBusinessImage,
        OtherServicesImage,
    ];

    return (
        <>
            <Section className='bg-brand-5 w-full' fullWidth>
                <Hero
                    headline={t('We help you to setup and manage your business in Slovakia.')}
                    subheadline={'Expand your business to heart of Europe — The Slovak Republic — Legalia helps you to establish your business without even visiting it.'}
                    heroImage={HeroImage}
                    buttons={(
                        <div>
                            <Link to={'/order-form'}>
                                <Button className="mr-8">
                                   Start your business
                                </Button>
                            </Link>
                            <Link to='/#what-we-do'>Learn more</Link>
                        </div>
                    )}
                />
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading type='h3' className={'text-ink-40'}>Our offer is simple —</Heading>
                    <Heading id={'what-we-do'} type={'h2'}>
                        What we do
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-10'}>
                    {Array(4).fill().map((a, index) => (
                        <div className={'flex'}>
                            <div className={'flex w-1/3 justify-start mr-4 items-start'}>
                                <img src={whatImages[index]} alt="" className={'object-cover'}/>
                            </div>
                            <div className={'w-2/3'}>
                                <Heading type="h5" className={'font-semibold'}>Setup a business</Heading>
                                <p className={'text-ink-60 mt-2'}>We make peoples lives easier by registering their new business with authorities for them.</p>
                                <ul className={'mt-4 space-y-2'}>
                                    <li>
                                        <Link to='/' className={'link'}>Živnosť (Freelance)</Link>
                                    </li>
                                    <li>
                                        <Link to='/' className={'link'}>S. R. O. (Limited Liability Company)</Link>
                                    </li>
                                    <li>
                                        <Link to='/' className={'link'}>Občianské združenie (Civic assoctiation)</Link>
                                    </li>
                                    <li>
                                        <Link to='/' className={'link'}>J. A. S. (Simple stock company)</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex mb-16'}>
                    <div className={'w-full lg:w-1/2 flex justify-center lg:order-last mb-10 lg:mb-0'}>
                        <img src={StartYourBusinessImage} alt=""/>
                    </div>
                    <div className={'w-full lg:w-1/2'}>
                        <Heading type='h3'>Enjoy and relax — </Heading>
                        <Heading type='h2'>We start your business for you</Heading>
                        <p className={'text-white mb-12'}>Slovak’s legislation allows 3rd party companies to create a business instead of you. So you do not need to go through all state departments yourself. </p>
                        <Button variant="secondary">Start your business</Button>
                    </div>
                </div>
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-24 text-white'}>
                    {Array(6).fill().map(() => (
                        <div>
                            <Heading type='h5' className={'font-semibold'}>Registration with authorities</Heading>
                            <p className={'text-ink-10'}>It usually takes 3-5 business days for state to process your request. After that, you will be provided with all documents that confirm your business in Slovakia.</p>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-16'}>
                    <Heading type={'h2'} className={'text-center'}>
                        Why us
                    </Heading>
                    <img src={WhyUsUnderline} className={'inline-block'} alt=""/>
                </div>
                <div>
                    <Testimonials/>
                </div>
                <div className={'grid lg:grid-cols-4 gap-x-10 mt-24'}>
                    <div className={'text-center'}>
                        <Counter end={10000} suffix="+" className="text-h2 font-bold font-sans" />
                        <p>companies estabilished in our lifetime</p>
                    </div>
                    <div className={'text-center'}>
                        <Counter end={26000} suffix="+" className="text-h2 font-bold font-sans" />
                        <p>hours spent dealing with authorities for our clients</p>
                    </div>
                    <div className={'text-center'}>
                        <Counter end={10000} suffix="+" className="text-h2 font-bold font-sans"/>
                        <p>documents signed by our clients</p>
                    </div>
                    <div className={'text-center'}>
                        <Counter end={8000} suffix="+" className="text-h2 font-bold font-sans"/>
                        <p>satisfied customers enjoying doing their business</p>
                    </div>
                </div>
            </Section>
            <Container>
                <hr/>
            </Container>
            <Section className={'py-20'}>
                <div className={'flex mb-16 flex-wrap justify-center'}>
                    <div className={'w-full lg:w-2/5 flex justify-center items-start lg:order-last mb-10 lg:mb-0'}>
                        <img src={FaqIcon} alt="" className={'w-full max-w-lg'}/>
                    </div>
                    <div className={'w-full lg:w-3/5'}>
                        <Heading type='h3' className={'text-ink-40'}>Frequently asked —</Heading>
                        <Heading type='h2'>Questions & Answers</Heading>
                        <div className={'py-12 sm:py-16'}>
                            <Faqs items={[]}/>
                        </div>
                        <Button variant="border">Show all</Button>
                    </div>
                </div>
            </Section>
            <FeaturedPostsSection/>
            <Section className={'py-20 bg-black text-white'}>
                <div className={'mb-12'}>
                    <Heading type='h2' className={'text-center font-bold max-w-3xl mx-auto'}>Stop wasting your time and money. Start your S.R.O. business today.</Heading>
                </div>
                <div className={'flex justify-center items-center flex-col md:flex-row gap-8'}>
                    <Button variant="secondary">Get started now</Button>
                    <Button variant="border">Contact us</Button>
                </div>
            </Section>
        </>
    );
};

// export const query = graphql`
//   query Page($locale: String!, $title: String!) {
//     markdownRemark(
//       frontmatter: { title: { eq: $title } }
//       fields: { locale: { eq: $locale } }
//     ) {
//       frontmatter {
//         title
//         description
//       }
//       html
//     }
//   }
// `;

export default IndexPage;
